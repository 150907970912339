@import 'reset.scss';
@import 'variables.scss';
@import 'media.scss';
@import 'general.scss';
@import '~currency-flags/dist/currency-flags.css';
@import 'fontawesome/fontawesome.scss';
@import 'fontawesome/brands.scss';
@import 'fontawesome/duotone.scss';
@import 'fontawesome/light.scss';
@import 'fontawesome/regular.scss';
@import 'fontawesome/solid.scss';
@import 'fontawesome/v4-shims.scss';

//layout
html, body, #root, .app  {
    height: 100%;
    -webkit-font-smoothing: antialiased;
}

body {
    color: $black;
    font-size: 10px;
    @include dm-sans-regular;
}

#page-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

#page-routes {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
}

#page-navigation {
    width: 200px;
    background-color: $light-grey;
}

.page-body {
    flex: 1;
    background-color: $pageBody;

    &.authed {
        flex: 1;
        background-color: $pageBody;
        padding: 70px 20px;
    }
}

.authed {
    padding: 70px 0;
}

h1, h2, h3, h4, h5, h6 { 
    margin-bottom: 16px;
    @include es-build-medium;
}



.bg {
  background-color: #F9FAFB;
}

strong {
    font-weight: bold;
}

.error-message {
    margin: 0 auto;
    text-align: center;
    font-size: 160%;
}

.data-check-loading, .data-check-error, .data-check-no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 36px 0 ;
    font-size: 250%;

    i {
        margin-bottom: 16px;
    }
}

.component-data-check-loading, .component-data-check-no-data, .component-data {
    display: inline-flex;
    padding: 0 4px;
}

.powered-by-transferwise {
    width: 280px;
    margin-bottom: 12px;

    img {
        max-width: 100%;
    }
}

a {
    text-decoration: none;
}

.password-description-text {
    margin-top: 16px;
    font-size: 130%;
}

@import 'pages/dark-mode/index';

@import 'components/account-header';
@import 'components/arrows';
@import 'components/brand';
@import 'components/container';
@import 'components/form';
@import 'components/header';
@import 'components/modals';
@import 'components/menu-item';
@import 'components/sectionTitle';
@import 'components/search-and-list';
@import 'components/adverts';
@import 'components/password-meter';
@import 'components/switch';
@import 'components/wysiwyg';
@import 'components/google-auto-complete.scss';
@import 'components/help-button.scss';

@import 'pages/login';
@import 'pages/transactions';
@import 'pages/cards';
@import 'pages/pin-options';
@import 'pages/payments';
@import 'pages/manage-payee';
@import 'pages/international/international-transferwise';
@import 'pages/international/international-select-recipient';
@import 'pages/international/international-calculator';
@import 'pages/international/international-review';
@import 'pages/international/international-status';
@import 'pages/more';
@import 'pages/manage-subscriptions';
@import 'pages/help';
.sm-page-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 32px;

    @include devices(sm) {
        max-width: 520px;
    }

    @include devices(md) {
        max-width: 720px;
    }

    @include devices(lg) {
        max-width: 960px;
    }

    @include devices(xl) {
        max-width: 1024px;
    }

    &.row {
        flex-direction: row;
    }

    &.center {
        justify-content: center;
        align-items: center;
    }

    &.mini {
        max-width: 540px;
        margin: 0 auto;
    }

    &.extraMini {
        max-width: 360px;
        margin: 0 auto;
    }

    .sm-form {
        .warning {
            font-size: 140%;
            color: $darkOrange;
            &.create-payee-warning {
                font-size: 20px;
                line-height: 26px;
            }
        }
    }
}

.sm-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &.row {
        flex-direction: row;

        &.v-center {
            justify-content: center;
        }
    }

    &.v-center {
        align-items: center;
    }

    &.center {
        justify-content: center;
        align-items: center;
    }
}

.sm-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &.row {
        flex-direction: row;
    }

    &.center {
        align-items: center;
        justify-content: center;
    }
}

.sm-terms-and-conditions {

    .section {
        margin-bottom: 48px;
    }
}

.sm-grid-container {
    display: grid;

    &.full-width {
        width: 100%;
    }

    @media (max-width: 960px) {
        grid-template-columns: 1fr !important;
    }
}

.sm-button-container {
    display: flex;

    .sm-button,
    button {
        margin-right: 32px;
        text-align: center;

        @include last-child {
            margin-right: 0;
        }
    }

    &.no-display {
        display: none;
    }

    &.center {
        justify-content: center;
    }
}

.sm-button {
    width: 50%;
    margin-top: 24px;
    padding: 17px 15px;
    font-size: 250%;
    @include dm-sans-medium;
    background-color: $black;
    color: white;
    border-radius: 5px;
    line-height: 1;
    text-align: center;

    &.invisible-backdrop {
        background-color: rgba(0, 0, 0, 0);
        color: black;
    }

    &:hover {
        opacity: 0.9;
    }

    &:disabled {
        opacity: 0.3;
        cursor: not-allowed;

        &:hover {
            opacity: 0.3;
        }
    }

    .icon {
        margin-right: 16px;
    }

    &.unfreeze {
        background-color: $light-blue;
    }

    &.cancel {
        background-color: $cancelButton;
    }

    &.confirm {
        background-color: $confirmButton;
    }

    &.danger {
        background-color: $red;
    }

    &.logout {
        background-color: $red !important;
        color: $white !important;
    }

    &.outline {
        background-color: transparent;
        border: 2px $black solid;
        color: $black;
        transition: 200ms all ease-in-out;

        &:hover {
            background-color: $black;
            color: white;
        }
    }

    &.fit-content {
        display: block;
        width: auto;
        padding: 12px 72px;
    }

    &.small {
        max-width: 320px;
        font-size: 130%;
    }

    &.medium {
        max-width: 540px;
    }

    &.full {
        width: 100%;
    }

    &.center {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    &.auto-width {
        width: auto;
    }
}

.sm-infinite-scroll {

    &.small {
        max-height: 40vh;
        overflow-y: auto;
    }

    .loading-more {
        font-size: 200%;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.sm-login-container {

    .statement-container {
        text-align: center;
    }

    .styles_react-code-input-container__tpiKG {
        width: auto !important;
        margin: 0 auto;
        text-align: center;
        margin-top: 16px;

        input {
            border: 2px solid $blue !important;
            border-radius: 8px !important;
            background-color: $pageBody;
            margin-right: 6px;
            font-family: $dm-sans !important;
        }
    }

    .title {
        font-size: 350%;
        font-weight: 600;
        text-align: center;
        color: $black;
        margin-bottom: 48px;
    }

    .statement {
        font-size: 160%;
        line-height: 1.4;
        margin-top: 16px;

        a {
            color: $black;
            font-weight: 600;

            &:hover {
                text-decoration: underline;
            }
        }

        &.mb {
            margin-bottom: 16px;
        }
    }

    .secondary-title {
        font-size: 280%;
        font-weight: 600;
    }

    .help-statement {
        margin-top: 32px;
        padding: 48px 0;
        background-color: white;

        p {
            font-size: 160%;
            line-height: 1.6;
        }

        a {
            color: $black;
            font-weight: 600;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .login-footer {
        margin-top: 48px;
        text-align: center;



        .footer-links {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 16px;
            font-size: 160%;

            a {
                color: $black;

                &:hover {
                    text-decoration: underline;
                }
            }

            span {
                margin: 0 12px;
                width: 8px;
                height: 0.6px;
                background-color: $grey;
            }
        }

        .copyright {
            font-size: 160%;
        }
    }

    .login-info-circle {
        border: 3px solid $black;
        border-radius: 50%;
        padding: 5px 9px 4px;

        i {
            font-size: 140%;
            color: $black;
        }
    }
}

.sm-input {
    position: relative;

    .sm-input-append-icon {
        position: absolute;
        right: 0;
        font-size: 180%;

        i {
            margin-top: 25%;
            cursor: pointer;
            z-index: 4;
        }
    }

    .sm-input-prepend-icon {
        position: absolute;
        left: 0;
        font-size: 200%;
    }

    &.disabled {
        opacity: 0.7
    }
}

.sm-bullet-point {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0 18px;

    p {
        font-size: 160%;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.small {

        p {
            font-size: 100%;
        }
    }

    .left {
        display: flex;
        align-items: flex-start;

        .bullet-point {
            display: block;
            min-width: 13px;
            height: 13px;
            border: 3px solid $black;
            border-radius: 100%;
            margin-right: 18px;
            margin-top: 5px;

            &.dash {
                border-radius: 0;
                height: 1px;
                border: 1px solid $black;
                margin-top: 10px;
            }
        }
    }

    &.fees {
        .left {
            .bullet-point {
                margin-top: 1px;
            }
        }
    }
}

.sm-typography {

    &.error {
        text-align: center;
        font-size: 240%;
    }
}

.sm-accordion {

    .menu-item-container {
        margin-bottom: 0;

        .menu-item-content {
            padding-right: 16px;
        }

        .menu-item-arrow {

            i {
                transform: rotate(0deg);
                transform-origin: 50% 50%;
                transition: transform 0.3s ease-in-out;
            }
        }

        &.open {

            .menu-item-arrow {

                i {
                    transform: rotate(90deg);
                    transform-origin: 50% 50%;
                    transition: transform 0.35s ease-in-out;
                }
            }
        }
    }

    .accordion-item {
        overflow: hidden;
        transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
        height: auto;
        max-height: 9999px;

        &.collapsed {
            max-height: 0px;
            transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
        }

        .accordion-content {
            font-size: 180%;
            padding: 12px 16px 24px;
            line-height: 1.4;

            a {
                color: $blue;
                font-weight: 600;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.w-100 {
    width: 100%;
}

.d-block {
    display: block;
}

.d-flex-col-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mt-0 {
    margin-top: 0px;
}

.mt-1 {
    margin-top: 10px;
}

.mt-2 {
    margin-top: 20px;
}

.mt-3 {
    margin-top: 30px;
}

.mt-4 {
    margin-top: 50px;
}

.mb-0 {
    margin-bottom: 0px;
}

.mb-1 {
    margin-bottom: 10px;
}

.mb-2 {
    margin-bottom: 20px;
}

.mb-3 {
    margin-bottom: 30px;
}

.mb-4 {
    margin-bottom: 50px;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.login-container {
    width: 450px;
    height: 80vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.checking-container {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #0000003b;
        backdrop-filter: blur(2px);
    }

    h1 {
        font-size: 40px;
        text-align: center;
        @media(max-width:500px) {
            font-size: 30px;
        }
    }

    p {
        font-size: 20px;
        text-align: center;
        line-height: 30px;
        @media(max-width:500px) {
            font-size: 16px;
        }
    }

    span {
        font-size: 18px;
        text-align: center;
    }

    form {
        width: 100%;
    }

    .logo {
        width: 400px;

        @media(max-width:440px) {
            width: 100%;
        }
    }

    .email-button {
        background-color: transparent;
        color: black;
        font-size: 17px;
        padding: 0;
        width: 100%;
    }

    .apply-buttons {
        width: 100%;

        a,
        button {
            width: 100%;
        }

        .applyBtn {
            background-color: #FF7636;
        }
    }

    input {
        &::placeholder {
            @media(max-width:500px) {
                font-size: 16px;
            }
        }
    }

    .button-group {
        width: 100%;
        height: 150px;
        @media(max-width:500px) {
            height: unset;
        }
    }

    .email-code-input {
        padding: 10px;
    }

    .gradient-box {
        background: linear-gradient(0.25turn, #5B59F3, #C170FF);
        text-align: center;
        padding: 20px;
        border-radius: 10px;
        width: 80%;

        span {
            display: block;
        }

        h1,
        p {
            color: white;
        }

        h1 {
            font-size: 35px;
            @media(max-width:500px) {
                font-size: 30px;
            }
        }

        p {
            line-height: 30px;
            @media(max-width:500px) {
                line-height: 25px;
            }
        }

        @media(max-width:500px) {
            width: 90%;
        }
    }

    .code-input {
        width: 100% !important;
        margin-top: 30px;

        .styles_react-code-input__CRulA {
            display: flex;
            justify-content: space-evenly;
            width: 100%;

            input {
                border: 1px solid #a8adb7;
                border-radius: 5px;
                width: 60px;
                height: 60px;
                font-size: 22px;
                font-family: monospace;
            }
        }
    }

    .checking-box {
        width: 100%;
        div {
            background-color: white;
            border: 2px solid black;
            border-radius: 10px;
            padding: 40px 20px;
            text-align: center;
            margin: auto;
        }
        div.dark-mode {
            background-color: $black;
            border-color: $white;
        }

        .checking-content {
            width: 400px;
        }

        .error-content {
            width: 60%;
        }

        p {
            font-weight: bold;
        }

        h1 {
            font-size: 30px;
            line-height: 32px;
            margin-bottom: 0;
        }

        img {
            animation-name: spin;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            margin-top: 20px;

            &.imageSuccess {
                animation: none;
            }
        }

        @media (max-width:900px) {
            .error-content,.checking-content {
                width: 90%;
            }
        }
    }

    button {
        @media(max-width:500px) {
            font-size: 20px;
        }
    }

    @media (max-width:600px) {
        width: 95%;
    }
}

button {
    &.dark {
        background-color: $white;
        color: $black;
    }

    &.light {
        background-color: $black;
        color: $white;
    }
}

.link-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.text-white {
    color: white !important;
}
.text-dark {
    color: black !important;
}

.react-datepicker__input-container {
    margin-top: 20px;
    padding: 0 40px;
}

.fa-calendar {
    position: absolute;
    right: 50px;
    top: 50%;
    font-size: 20px;
}

.border-box {
    background-color: $white;
    &.dark-mode {
        background-color: $black;
        border: 1px solid $white;
    }
}

.account-list {
    margin-top: 20px;
    .account-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    
        p {
            font-size: 20px;
        }
    
        .accountItemInnerContainer {
            position: relative;
            width: 30%;
            text-align: left;
            span {
                position: absolute;
                left:0;
                top: -12px;
                font-size: 12px;
            }
        }
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.tab {
    .tab-buttons {
        display: flex;
        justify-content: center;

        button {
            margin: 20px;
            padding: 10px 20px;
            font-size: 20px;
            border: 1px solid $black;
            border-radius: 5px;
            &.active {
                border-radius: 5px;
                background-color: $black;
                color: $white;
            }

            &.dark-mode {
                color: $white;
                background-color: $black;
                border-color: $white;

                &.active {
                    background-color: $white;
                    color: $black;
                }
            }
        }
    }

    .sub-tab-buttons {
        display: flex;
        justify-content: center;

        button {
            font-size: 18px;
            color: $black;
            margin: 20px;
            padding: 10px;
            border: none;

            &.active {
                border-bottom: 1px solid $black;
                font-weight: bold;
            }

            &.dark-mode {
                color: $white;
                border-color: $white;
                &.active {
                    border-bottom: 1px solid $white;
                }
            }
        }
    }
}

.detail-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    .detail-row-icon {
        width: 20px;
        margin-right: 10px;
        background-color: $white;
    }
}